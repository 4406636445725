import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {ERROR_MSG, MONTHS} from '_helper/constants'
import {ReactComponent as ArrowLeft} from '_asset/svg/arrow_left.svg'
import {ReactComponent as ArrowRight} from '_asset/svg/arrow_right.svg'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import {fireErrorToast} from '_helper/functions'

import {ReactComponent as DarkMode} from '_asset/svg/darkMode.svg'
import {ReactComponent as LightMode} from '_asset/svg/lightMode.svg'

const PageHeader = (props) => {
  const {
    selectedMonth,
    selectedYear,
    previousMonth,
    nextMonth,
    lightScheme,
    setDarkMode,
    setLightMode,
  } = props

  const [topics, setTopics] = useState([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    httpClient
      .get(endpoints.topics)
      .then((res) => {
        setTopics(res.data.objects)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
        fireErrorToast(ERROR_MSG)
        console.error(err)
      })
  }, [])

  const renderTopics = () => {
    if (topics && topics.length !== 0) {
      return topics.map((topic, index) => (
        <span key={index} className="topic">
          {topic.name}
        </span>
      ))
    }
    return (
      <span>
        <Trans>No current topic</Trans>
      </span>
    )
  }

  return (
    <div className="header-wrapper">
      <div className="color-mode-icon">
        {lightScheme ? (
          <DarkMode className="dark-mode" onClick={setDarkMode} />
        ) : (
          <LightMode className="light-mode" onClick={setLightMode} />
        )}
      </div>
      <div className="header-month">
        {selectedMonth === 11 && selectedYear === 2020 ? (
          <span className="empty" />
        ) : (
          <ArrowLeft onClick={previousMonth} />
        )}
        <span className="header-text month">{MONTHS[selectedMonth]}</span>
        <span className="header-text">{selectedYear}</span>
        <ArrowRight onClick={nextMonth} />
      </div>
      <hr className="header-divider" />
      {isLoading ? (
        <div className="loader" />
      ) : (
        <span className="header-text">{renderTopics()}</span>
      )}
    </div>
  )
}

PageHeader.propTypes = {
  selectedMonth: PropTypes.number.isRequired,
  selectedYear: PropTypes.number.isRequired,
  previousMonth: PropTypes.func.isRequired,
  nextMonth: PropTypes.func.isRequired,
}

export default PageHeader
