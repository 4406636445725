import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Trans} from '@lingui/macro'
import moment from 'moment'
import TopEventCell from '_component/topEvent/TopEventCell'
import EventDetail from '_component/eventDetail/EventDetail'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import {fireErrorToast} from '_helper/functions'
import {DATE_FORMAT, ERROR_MSG, NUMBER_OF_TOP_EVENTS_IN_ROW} from '_helper/constants'
import PropTypes from 'prop-types'
import {EventsContext} from '_helper/context'

const TopEvents = (props) => {
  const {mobileView} = props
  const [topEvents, setTopEvents] = useState([])
  const [detailTopEvent, setDetailTopEvent] = useState({visible: false, data: null})

  let {state, dispatch} = useContext(EventsContext)

  const openEventDetail = (cell) => (event) => {
    setDetailTopEvent({visible: true, data: cell})
  }

  const closeEventDetail = () => (event) => {
    setDetailTopEvent({visible: false, data: null})
  }

  const prepareQuery = () => {
    const from = moment().format(DATE_FORMAT)
    const to = moment().add(3, 'M').format(DATE_FORMAT)

    return {
      from: from,
      to: to,
    }
  }

  const orderWeekDetail = (data) => {
    data.sort((a, b) => moment(a.from) - moment(b.from))

    setTopEvents(data)
  }

  const fetchTopEvents = useCallback(() => {
    dispatch({type: 'BEGIN_TOP_EVENTS_LOADING'})
    httpClient
      .get(`${endpoints.event}/top`, prepareQuery())
      .then((res) => {
        orderWeekDetail(res.data.objects)
      })
      .catch((err) => {
        fireErrorToast(ERROR_MSG)
        console.error(err)
      })
      .finally(() => {
        dispatch({type: 'END_TOP_EVENTS_LOADING'})
      })
  }, [])

  useEffect(() => {
    fetchTopEvents()
  }, [fetchTopEvents])

  useEffect(() => {
    const offsetTop = document.querySelector('.table-wrapper.top-event').offsetTop
    window.scrollTo({top: offsetTop, left: 0, behavior: 'smooth'})
    dispatch({type: 'TOGGLE_RESIZE', data: true})
  }, [detailTopEvent])

  const renderTopEvents = () => {
    let topEventsData = []
    let numberOfEventsInRow = mobileView ? 1 : NUMBER_OF_TOP_EVENTS_IN_ROW
    for (let i = 0; i <= topEvents.length; i += numberOfEventsInRow) {
      const row = topEvents.slice(i, i + numberOfEventsInRow)
      topEventsData.push(
        <tr key={i}>
          {row.map((cell, cellIndex) => (
            <TopEventCell
              color="magenta"
              cell={cell}
              key={`top-event-${cellIndex}`}
              selectTopEvent={openEventDetail}
            />
          ))}
          {/* To not stretch single TOP event to whole row */}
          {!mobileView && row.length === 1 && <td />}
        </tr>
      )
    }
    return topEventsData
  }

  const checkIfEmpty = () => {
    return topEvents.length === 0
  }

  return (
    <>
      <div className="divider top-event" id="top-event-divider">
        <Trans>TOP EVENTS</Trans>
      </div>
      <div className="table-wrapper top-event">
        {state.topEventsLoading ? (
          <div className="loader-wrapper">
            <div className="loader" />
          </div>
        ) : !checkIfEmpty() ? (
          <table>
            <tbody>
              {detailTopEvent.visible ? (
                <tr>
                  <td
                    className="detail-cell"
                    colSpan={mobileView ? 1 : NUMBER_OF_TOP_EVENTS_IN_ROW}
                  >
                    <EventDetail
                      closeEventDetail={closeEventDetail}
                      data={detailTopEvent.data}
                      mobileView={mobileView}
                    />
                  </td>
                </tr>
              ) : (
                renderTopEvents()
              )}
            </tbody>
          </table>
        ) : (
          <div className="empty-cell">
            <Trans>No top events</Trans>
          </div>
        )}
      </div>
    </>
  )
}

TopEvents.propTypes = {
  mobileView: PropTypes.bool,
}

export default TopEvents
