import React, {useContext, useEffect} from 'react'
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import './App.scss'
import CalendarPage from 'App/page/CalendarPage'
import {ToastContainer} from 'react-toastify'
import {LANGUAGE_CATALOGS} from '_config/language'
import UnsubscribePage from 'App/page/UnsubscribePage'
import {I18nProvider} from '@lingui/react'
import {EventsContext} from '_helper/context'
import {IFRAME_URL} from '_helper/constants'

let parent = null

const App = () => {
  let {state, dispatch} = useContext(EventsContext)

  window.addEventListener('message', function (e) {
    if (parent === null) {
      parent = e.source
      parent.postMessage(document.getElementById('root').getBoundingClientRect().height, IFRAME_URL)

      // hide scroll bar
      const html = document.getElementsByTagName('html')
      html[0].classList.add('scroll-hidden')
    }
  })

  let rtime
  let timeout = false
  const delta = 100

  const resizeEnd = () => {
    if (new Date() - rtime < delta) {
      setTimeout(resizeEnd, delta)
    } else {
      timeout = false
      parent &&
        parent.postMessage(
          document.getElementById('root').getBoundingClientRect().height,
          IFRAME_URL
        )
    }
  }

  window.addEventListener('resize', function (e) {
    rtime = new Date()
    if (timeout === false) {
      timeout = true
      setTimeout(resizeEnd, delta)
    }
  })

  useEffect(() => {
    dispatch({type: 'TOGGLE_RESIZE', data: false})
    parent &&
      parent.postMessage(document.getElementById('root').getBoundingClientRect().height, IFRAME_URL)
  }, [state.resize])

  return (
    <I18nProvider language={'cs'} catalogs={LANGUAGE_CATALOGS}>
      <ToastContainer
        position="top-right"
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        newestOnTop
        closeButton={false}
        autoClose={3000}
      />
      <Router>
        <Switch>
          <Route exact path={'/events/:id/date/:date/unsubscribe'} component={UnsubscribePage} />
          <Route
            exact
            path={['/events/:id([0-9]+)/date/:date', '/events/:date', '/events']}
            component={CalendarPage}
          />
          <Redirect to="/events" />
        </Switch>
      </Router>
    </I18nProvider>
  )
}

export default App

/*
on our side (our app) must have the origin of postMessage() where the iframe is hosted
parent.postMessage(
      document.getElementById('root').getBoundingClientRect().height,
      'https://www.t-mobile.cz/microsites/magenta-experience/udalosti.html'
    )

SRC of iframe  and postMessages must be the same as url where our app is hosted
iframe.contentWindow.postMessage(
    'init',
    'https://magenta.eventito.com/events'
  )

CODE on side of T-mobile

<iframe
    width="100%"
    id="iframe"
    src="https://magenta.eventito.com/events"
    frameborder="1"
></iframe>

<script>
  // assign variables with references to the DOM nodes we will be interacting with
  const iframe = document.getElementById('iframe')
  // onload send an init message in order to calendar to know the origin
  // otherwise inside of iframe dont know what is outside
  iframe.onload = function () {
    iframe.contentWindow.postMessage(
      'init',
      'https://magenta.eventito.com/events'
    )
  }

  // This event listener will run when the embedded page sends us a message
  window.addEventListener(
    'message',
    function (event) {
      // extract the data from the message event
      const {data} = event
      // set iframe height
      iframe.height = data + 'px'
    },
    {passive: false}
  )
</script>
*/
