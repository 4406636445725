import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const RoundedButton = (props) => {
  const {text, onClick, selected, staticBtn, icon} = props

  let className = cx({
    'static-btn': staticBtn,
    'selected-btn': selected,
    'rounded-button': true,
  })

  return (
    <button onClick={onClick} className={className}>
      <div className="button-content">
        <div className="button-icon">{icon}</div>
        <div className="button-text">{text}</div>
      </div>
    </button>
  )
}

RoundedButton.propTypes = {
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  staticBtn: PropTypes.bool,
  icon: PropTypes.node.isRequired,
}

export default RoundedButton
