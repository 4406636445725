import React from 'react'

let initialState = {topEventsLoading: true, eventsLoading: true, resize: false}

let EventsContext = React.createContext()

let reducer = (state, action) => {
  switch (action.type) {
    case 'BEGIN_EVENTS_LOADING':
      return {...state, eventsLoading: true}
    case 'END_EVENTS_LOADING':
      return {...state, eventsLoading: false}
    case 'BEGIN_TOP_EVENTS_LOADING':
      return {
        ...state,
        topEventsLoading: true,
      }
    case 'END_TOP_EVENTS_LOADING':
      return {
        ...state,
        topEventsLoading: false,
      }
    case 'TOGGLE_RESIZE':
      return {
        ...state,
        resize: action.data,
      }
    default:
      return state
  }
}

function EventsContextProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, initialState)
  let value = {state, dispatch}

  return <EventsContext.Provider value={value}>{props.children}</EventsContext.Provider>
}

let EventsContextConsumer = EventsContext.Consumer

export {EventsContext, EventsContextProvider, EventsContextConsumer}
