import React, {useContext, useEffect} from 'react'
import {
  getDay,
  getDayAndMonthDate,
  getEndTime,
  getEnumPart,
  getTime,
} from '_helper/calendarFunctions'
import {PLATFORM, PRIVACY, TIME_SLOT, ZONES} from '_helper/constants'
import RoundedButton from '_component/RoundedButton'
import {Trans} from '@lingui/macro'
import DetailButton from '_component/DetailButton'
import PropTypes from 'prop-types'
import {ReactComponent as ArrowLeft} from '_asset/svg/arrow_left.svg'
import {EventsContext} from '_helper/context'

const DayDetailEvent = (props) => {
  const {timeSlotEventsData, timeSlotIndex, firstNonEmptyIndex, mobileView} = props
  const {openEventDetail, closeDayDetail} = props
  let {dispatch} = useContext(EventsContext)

  useEffect(() => {
    dispatch({type: 'TOGGLE_RESIZE', data: true})
  }, [])

  return (
    <>
      {timeSlotEventsData.map((event, index) => {
        const {from, zone, name, organization, platform, privacy, pictureUrl, length} = event
        return (
          <div key={index}>
            <div className="header-wrapper">
              {index === 0 && timeSlotIndex === firstNonEmptyIndex && from && (
                <>
                  {!mobileView && closeDayDetail && (
                    <div className="button-icon">
                      <ArrowLeft onClick={closeDayDetail} />
                    </div>
                  )}
                  <div className="header-day">
                    <span className="header-text">{getDay(from)}</span>
                    <span className="header-text day">{getDayAndMonthDate(from)}</span>
                  </div>
                </>
              )}
              <hr className="header-divider" />
              {index === 0 && <span className="header-text">{TIME_SLOT[timeSlotIndex].label}</span>}
            </div>

            {mobileView ? (
              <div key={index} className="detail-cell-wrapper">
                <div className="event-information">
                  <div className="name">{name && name}</div>
                  <div className="organization-time">
                    <div className="organization">{organization && organization.name}</div>
                    <div className="time">
                      <span>{from && getTime(from)}</span>
                      <span>&nbsp;&#8208;&nbsp;{from && length && getEndTime(from, length)}</span>
                    </div>
                  </div>
                  <div className="more-detail-button">
                    <div className="buttons-logo">
                      <div className="image big">
                        {pictureUrl ? (
                          <img alt="logo" src={pictureUrl} />
                        ) : organization && organization.logoUrl ? (
                          <img alt="logo" src={organization.logoUrl} />
                        ) : (
                          <div className="empty-image">
                            <div className="logo-text">Logo/foto</div>
                          </div>
                        )}
                      </div>
                      <div className="detail">
                        {platform && (
                          <div className="button">
                            <RoundedButton
                              icon={getEnumPart(PLATFORM, platform, 'icon')}
                              text={getEnumPart(PLATFORM, platform, 'label')}
                              staticBtn
                            />
                          </div>
                        )}
                        {privacy && (
                          <div className="button">
                            <RoundedButton
                              icon={getEnumPart(PRIVACY, privacy, 'icon')}
                              text={getEnumPart(PRIVACY, privacy, 'label')}
                              staticBtn
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <DetailButton
                      onClick={openEventDetail(event)}
                      text={<Trans>More details</Trans>}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div key={index} className="detail-cell-wrapper">
                {zone && (
                  <div className="event-image">
                    <img alt="icon" src={getEnumPart(ZONES, zone, 'icon')} />
                    {/*<div className="event-image-text">{getEnumPart(ZONES, zone, 'label')}</div>*/}
                  </div>
                )}

                <div className="event-information">
                  <div className="name">{name && name}</div>
                  <div className="organization">{organization && organization.name}</div>
                  <div className="detail">
                    <div className="time">
                      <span>{from && getTime(from)}</span>
                      <span>&nbsp;&#8208;&nbsp;{from && length && getEndTime(from, length)}</span>
                    </div>
                    {platform && (
                      <div className="button">
                        <RoundedButton
                          icon={getEnumPart(PLATFORM, platform, 'icon')}
                          text={getEnumPart(PLATFORM, platform, 'label')}
                          staticBtn
                        />
                      </div>
                    )}
                    {privacy && (
                      <div className="button">
                        <RoundedButton
                          icon={getEnumPart(PRIVACY, privacy, 'icon')}
                          text={getEnumPart(PRIVACY, privacy, 'label')}
                          staticBtn
                        />
                      </div>
                    )}
                  </div>
                  <div className="more-detail-button">
                    <DetailButton
                      onClick={openEventDetail(event)}
                      text={<Trans>More details</Trans>}
                    />
                  </div>
                </div>

                <div className="image big">
                  {pictureUrl ? (
                    <img alt="logo" src={pictureUrl} />
                  ) : organization && organization.logoUrl ? (
                    <img alt="logo" src={organization.logoUrl} />
                  ) : (
                    <div className="empty-image">
                      <div className="logo-text">Logo/foto</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

DayDetailEvent.propTypes = {
  eventData: PropTypes.array,
  eventIndex: PropTypes.number,
  timeSlotIndex: PropTypes.number,
  openEventDetail: PropTypes.func,
  mobileView: PropTypes.bool,
}

export default DayDetailEvent
