import React, {useContext, useEffect} from 'react'
import PropTypes from 'prop-types'
import DayDetailEvent from './DayDetailEvent'
import {EventsContext} from '_helper/context'

const DayDetailTimeSlot = (props) => {
  const {data, openEventDetail, mobileView, closeDayDetail} = props

  let {dispatch} = useContext(EventsContext)

  const findFirstNonEmptySlot = () => {
    return data.findIndex((slot) => slot.events.length !== 0)
  }

  useEffect(() => {
    dispatch({type: 'TOGGLE_RESIZE', data: true})
  }, [data])

  return (
    <div className="detail-cell-content">
      {data.map((timeSlotData, timeSlotIndex) => (
        <DayDetailEvent
          mobileView={mobileView}
          key={timeSlotIndex}
          openEventDetail={openEventDetail}
          timeSlotIndex={timeSlotIndex}
          timeSlotEventsData={timeSlotData.events}
          firstNonEmptyIndex={findFirstNonEmptySlot()}
          closeDayDetail={closeDayDetail && closeDayDetail}
        />
      ))}
      <div className="header-wrapper">
        <hr className="header-divider last" />
      </div>
    </div>
  )
}

DayDetailTimeSlot.propTypes = {
  data: PropTypes.array,
  openEventDetail: PropTypes.func,
  closeDayDetail: PropTypes.func,
  mobileView: PropTypes.bool,
}

export default DayDetailTimeSlot
