import React, {useContext, useEffect, useState} from 'react'
import moment from 'moment'
import PageHeader from '_component/PageHeader'
import PageFooter from '_component/PageFooter'
import TopEvents from '_component/topEvent/TopEvents'
import Calendar from '_component/Calendar'
import {DATE_FORMAT_REGEX} from '_helper/constants'
import {useHistory} from 'react-router-dom'
import {EventsContext} from '_helper/context'

const CalendarPage = (props) => {
  let {state, dispatch} = useContext(EventsContext)
  const {
    match: {
      params: {id, date},
    },
    location: {search},
  } = props

  const [mobileView, setMobileView] = useState(window.innerWidth < 600)
  let history = useHistory()

  let currentDate = moment()
  if (date && date.match(DATE_FORMAT_REGEX)) {
    currentDate = moment(date)
  }

  const initCalendar = () => {
    return currentDate.month()
  }

  const [selectedMonth, setSelectedMonth] = useState(initCalendar)
  const [selectedYear, setSelectedYear] = useState(currentDate.year())
  const [lightScheme, setLightScheme] = useState(false)

  const nextMonth = () => {
    // if selected is 11 - December then set 1 - January
    if (selectedMonth === 11) {
      setSelectedMonth(0)
      setSelectedYear((prevYear) => prevYear + 1)
    } else {
      setSelectedMonth((prevState) => prevState + 1)
    }
    return history.push('/events')
  }

  const previousMonth = () => {
    // if selected is 1 - January then set 11 - December
    if (selectedMonth === 0) {
      setSelectedMonth(11)
      setSelectedYear((prevYear) => prevYear - 1)
    } else {
      setSelectedMonth((prevState) => prevState - 1)
    }
    return history.push('/events')
  }

  const handleResizePhone = (e) => {
    if (e.target.innerWidth >= 600) {
      if (mobileView === true) {
        setMobileView(false)
      }
    } else {
      if (mobileView === false) {
        setMobileView(true)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', (e) => {
      handleResizePhone(e)
    })
  }, [mobileView])

  const setLightMode = () => {
    setLightScheme(true)
    document.querySelector('html').className = 'light-mode'
    try {
      localStorage.setItem('calendar-color-mode', 'light-mode')
    } catch {}
  }

  const setDarkMode = () => {
    setLightScheme(false)
    document.querySelector('html').className = 'dark-mode'
    try {
      localStorage.setItem('calendar-color-mode', 'dark-mode')
    } catch {}
  }

  useEffect(() => {
    let calendarColorMode = lightScheme ? 'light-mode' : 'dark-mode'
    try {
      calendarColorMode = localStorage.getItem('calendar-color-mode')
    } catch {}
    // set light mode through get parameter
    const mode = search.slice(1).split('=')[1]
    if (mode === 'light') {
      setLightMode()
    } else if (mode === 'dark') {
      setDarkMode()
    } else if (calendarColorMode === 'light-mode') {
      setLightMode()
    } else if (calendarColorMode === 'dark-mode') {
      setDarkMode()
    }
  }, [lightScheme])

  useEffect(() => {
    if (!state.topEventsLoading && !state.eventsLoading) {
      const element = document.querySelector('.row-selected')
      element && element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
      dispatch({type: 'TOGGLE_RESIZE', data: true})
    }
  }, [state.topEventsLoading, state.eventsLoading])

  return (
    <div className="page-wrapper" id="page-wrapper">
      <div className="content-wrapper">
        <PageHeader
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          nextMonth={nextMonth}
          previousMonth={previousMonth}
          setDarkMode={setDarkMode}
          setLightMode={setLightMode}
          lightScheme={lightScheme}
        />
        <Calendar
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
          mobileView={mobileView}
          id={id}
          date={date}
        />
        <TopEvents mobileView={mobileView} />
      </div>
      <PageFooter lightScheme={lightScheme} />
    </div>
  )
}

export default CalendarPage
