// import 'react-app-polyfill/ie11'
// import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from 'App/App'
import {EventsContextProvider} from '_helper/context'

ReactDOM.render(
  <EventsContextProvider>
    <App />
  </EventsContextProvider>,
  document.getElementById('root')
)
