import React, {useContext, useEffect, useState} from 'react'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import WeekDetailCell from '_component/weekDetail/WeekDetailCell'
import PropTypes from 'prop-types'
import {fireErrorToast} from '_helper/functions'
import {ARE_EMPTY_SLOTS_IN_WEEK_CALENDAR, ERROR_MSG} from '_helper/constants'
import {Trans} from '@lingui/macro'
import {getDate} from '_helper/calendarFunctions'
import moment from 'moment'
import {EventsContext} from '_helper/context'

const WeekDetail = (props) => {
  const {dates, onDayClick, platformFilter, updateCalendar} = props
  let {state, dispatch} = useContext(EventsContext)

  const [weekData, setWeekData] = useState([])

  const prepareQuery = (dates) => {
    return {
      platform: platformFilter,
      emptySlots: ARE_EMPTY_SLOTS_IN_WEEK_CALENDAR,
      from: getDate(dates[0].date),
      to: getDate(dates[dates.length - 1].date),
    }
  }

  const orderWeekDetail = (data) => {
    data.forEach((slot) => {
      return slot.events.map((events) => {
        return events.sort((a, b) => moment(a.from) - moment(b.from))
      })
    })

    let weekDetailData = []
    let initiateArraySize
    if (ARE_EMPTY_SLOTS_IN_WEEK_CALENDAR) {
      initiateArraySize = data[0].events.length
      weekDetailData = [...Array(initiateArraySize)].map((x) => Array(7).fill([]))

      data.map((day, dayIndex) => {
        if (!day.businessDayOff) {
          day.events.map((event, eventIndex) => {
            return (weekDetailData[eventIndex][dayIndex] = event)
          })
        } else {
          for (let i = 0; i < initiateArraySize; i++) {
            if (i === 0) {
              weekDetailData[i][dayIndex] = day.businessDayOff
            } else {
              weekDetailData[i].splice(dayIndex - 1, 1)
            }
          }
        }
      })
    } else {
      // initial number of rows, added one to Math.max to ensure at least one row
      // is rendered in case of holiday/ day off
      initiateArraySize = Math.max(
        ...data.map((timeSlot) => {
          let number = timeSlot.businessDayOff ? 1 : 0
          number += timeSlot.events.length ? timeSlot.events[0].length : 0
          return number
        }, 1)
      )

      weekDetailData = [...Array(initiateArraySize)].map((x) => [])

      data.map((day) => {
        if (!!day.businessDayOff) {
          weekDetailData[0].push(day.businessDayOff)
        }

        for (let i = !!day.businessDayOff ? 1 : 0; i < initiateArraySize; i++) {
          if (day.events[0][!!day.businessDayOff ? i - 1 : i]) {
            weekDetailData[i].push(day.events[0][!!day.businessDayOff ? i - 1 : i])
          } else {
            weekDetailData[i].push({})
          }
        }
      })
    }

    setWeekData(weekDetailData)
  }

  useEffect(() => {
    dispatch({type: 'BEGIN_EVENTS_LOADING'})
    httpClient
      .get(endpoints.events, prepareQuery(dates))
      .then((res) => {
        orderWeekDetail(res.data)
      })
      .catch((err) => {
        fireErrorToast(ERROR_MSG)
        console.error(err)
      })
      .finally(() => {
        dispatch({type: 'END_EVENTS_LOADING'})
      })
  }, [dates, platformFilter, updateCalendar])

  const checkIfEmptyWeek = (weekData) => {
    let isEmpty = true
    if (ARE_EMPTY_SLOTS_IN_WEEK_CALENDAR) {
      weekData.forEach((day) => {
        if (day.length !== 0) {
          isEmpty = false
        }
      })
    } else {
      weekData.forEach((row) => {
        row.forEach((col) => {
          if (Object.keys(col).length !== 0 && col.constructor === Object) {
            isEmpty = false
          }
        })
      })
    }
    return isEmpty
  }

  return (
    <tr className="week-detail">
      <td colSpan={7}>
        {!state.eventsLoading ? (
          !checkIfEmptyWeek(weekData) ? (
            <table>
              {/* for each time slot render one row, in order to separate them */}
              <tbody>
                {weekData.map((weekRow, weekRowIndex) =>
                  ARE_EMPTY_SLOTS_IN_WEEK_CALENDAR ? (
                    weekRow.length !== 0 && (
                      <tr key={`week-detail-row-${weekRowIndex}`}>
                        {weekRow.map((col, eventIndex) => (
                          <WeekDetailCell
                            key={`week-${weekRowIndex}-${eventIndex}`}
                            data={col}
                            onDayClick={onDayClick(eventIndex)}
                          />
                        ))}
                      </tr>
                    )
                  ) : (
                    <tr key={`week-detail-row-${weekRowIndex}`}>
                      {weekRow.map((col, eventIndex) => (
                        <WeekDetailCell
                          key={`week-${weekRowIndex}-${eventIndex}`}
                          data={col}
                          onDayClick={onDayClick(eventIndex)}
                        />
                      ))}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          ) : (
            <div className="empty-cell">
              <Trans>No events</Trans>
            </div>
          )
        ) : (
          <div className="loader-wrapper">
            <div className="loader" />
          </div>
        )}
      </td>
    </tr>
  )
}

WeekDetail.propTypes = {
  onDayClick: PropTypes.func,
  data: PropTypes.array,
  platformFilter: PropTypes.oneOf([null, 'ONLINE', 'OFFLINE']),
  updateCalendar: PropTypes.bool,
}

export default WeekDetail
