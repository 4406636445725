import React from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as ArrowRight} from '_asset/svg/arrow_right.svg'
import {getDayAndMonthDate, getEndTime, getEnumPart, getTime} from '_helper/calendarFunctions'
import {ZONES} from '_helper/constants'

const TopEventCell = (props) => {
  const {cell, selectTopEvent} = props
  const {from, zone, name, length} = cell

  return (
    <td>
      <div className="cell-wrapper">
        <div className="cell-detail-wrapper">
          <div className="cell-date">{from && getDayAndMonthDate(from)}</div>
          <div className="cell-event-time">
            <div>{from && getTime(from)}</div>
            &ensp;&ndash;&ensp;
            <div>{from && length && getEndTime(from, length)}</div>
          </div>
          <div className="cell-content">
            <div className="cell-detail">
              <div className="cell-event">
                <div className="cell-zone">{zone && getEnumPart(ZONES, zone, 'label')}</div>

                <div className="cell-event-name">{name && name}</div>
              </div>
            </div>
            <div className="cell-button" onClick={selectTopEvent(cell)}>
              <ArrowRight />
            </div>
          </div>
        </div>

        <div className="cell-image">
          <img alt="icon" src={zone && getEnumPart(ZONES, zone, 'noTextIcon')} />
        </div>
      </div>
    </td>
  )
}

TopEventCell.propTypes = {
  cell: PropTypes.object,
  selectTopEvent: PropTypes.func.isRequired,
}

export default TopEventCell
