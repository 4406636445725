import React, {useEffect, useState} from 'react'
import {Trans} from '@lingui/macro'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import {fireErrorToast, fireSuccessToast} from '_helper/functions'
import {ERROR_MSG} from '_helper/constants'

const UnsubscribePage = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [isUnsubscribed, setUnsubscribe] = useState(false)

  const {
    match: {
      params: {id, date},
    },
    location: {search},
  } = props

  const prepareToken = () => {
    return {token: search.split('=')[1]}
  }

  useEffect(() => {
    let newId = id.replace(',', '')
    setLoading(true)
    httpClient
      .put(`${endpoints.event}/${newId}/date/${date}/unsubscribe`, prepareToken())
      .then(() => {
        setLoading(false)
        fireSuccessToast(<Trans>You have been unsubscribed from the event</Trans>)
        setUnsubscribe(true)
      })
      .catch((err) => {
        setLoading(false)
        fireErrorToast(ERROR_MSG)
        setUnsubscribe(false)
        console.error(err)
      })
  }, [])

  return (
    <div className="unsubscribe-wrapper">
      <div className="unsubscribe-card">
        <div className="unsubscribe-text">
          {isLoading ? (
            <div className="loader" />
          ) : isUnsubscribed ? (
            <Trans>You have been unsubscribed from the event</Trans>
          ) : (
            <Trans>Something went wrong!</Trans>
          )}
        </div>
      </div>
    </div>
  )
}

export default UnsubscribePage
