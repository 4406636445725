import React from 'react'
import {toast} from 'react-toastify'
import Toast from '_component/Toast'
import {ENVIRONMENT, GATEWAY_URL} from '_helper/constants'
import {MOCK_API, MOCK_PORT_MAPPING} from '_config/configConstants'

export const createCalendarGrid = () => {
  let grid = []
  for (let row = 0; row < 5; row++) {
    grid.push([])
    for (let col = 0; col < 7; col++) {
      const cell = {}
      grid[row].push(cell)
    }
  }
  return grid
}

export const fireSuccessToast = (message, options) => {
  return toast.success(<Toast message={message} type="success" />, options)
}

export const fireErrorToast = (message, options = {autoClose: 5000}) => {
  return toast.error(<Toast message={message} type="error" />, options)
}

export const fireWarningToast = (message, options) => {
  return toast.warning(<Toast message={message} type="warning" />, options)
}

export const resolveEnvironmentParam = (name) => {
  const nameWithPrefix = `REACT_APP_${name}`
  if (ENVIRONMENT === 'production') {
    return window._env_[nameWithPrefix]
  }
  return process.env[nameWithPrefix]
}

export const getServiceUrl = (name) => {
  if (ENVIRONMENT !== 'production' && MOCK_API) {
    return `http://localhost:${MOCK_PORT_MAPPING[name]}`
  }
  return `${GATEWAY_URL}/api/${name}`
}

export const scrollToMainTable = () => {
  const offsetTop = document.getElementById('main-table').offsetTop
  window.scrollTo({top: offsetTop, left: 0, behavior: 'smooth'})
}

export const scrollToDetailRow = () => {
  const offsetTop = document.getElementById('detail-row').offsetTop
  window.scrollTo({top: offsetTop, left: 0, behavior: 'smooth'})
}
