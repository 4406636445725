import React from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as CheckCircle} from '_asset/svg/check_circle.svg'
import {ReactComponent as Warning} from '_asset/svg/warning.svg'
import {ReactComponent as Error} from '_asset/svg/error.svg'

const Toast = (props) => {
  const {message, type} = props

  const switchIcons = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircle />
      case 'warning':
        return <Warning />
      case 'error':
        return <Error />
      default:
    }
    return true
  }

  return (
    <div className="toast">
      {switchIcons(type)}
      <strong>{message}</strong>
    </div>
  )
}

Toast.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
}

export default Toast
