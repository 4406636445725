import React, {useContext, useEffect} from 'react'
import {
  getDate,
  getDay,
  getDayAndMonthDate,
  getEndTime,
  getEnumPart,
  getTime,
} from '_helper/calendarFunctions'
import PropTypes from 'prop-types'
import RoundedButton from '_component/RoundedButton'
import {ReactComponent as ArrowLeft} from '_asset/svg/arrow_left.svg'
import {ReactComponent as Clock} from '_asset/svg/clock.svg'
import EventForm from '_component/eventDetail/EventForm'
import {PLATFORM, PRIVACY, TIME_SLOT, ZONES} from '_helper/constants'
import EventDetailShare from '_component/eventDetail/EventDetailShare'
import {Trans} from '@lingui/macro'
import {EventsContext} from '_helper/context'

const EventDetail = (props) => {
  const {closeEventDetail, data, mobileView} = props
  let {dispatch} = useContext(EventsContext)
  const {
    zone,
    platform,
    privacy,
    description,
    from,
    timeSlot,
    name,
    id,
    organization,
    pictureUrl,
    length,
    hideRegistration,
  } = data

  useEffect(() => {
    dispatch({type: 'TOGGLE_RESIZE', data: true})
  }, [])

  return (
    <div key="detail-event" className="detail-cell-content">
      {Object.keys(data).length === 0 && data.constructor === Object ? (
        <div className="empty-cell day-detail">
          <Trans>No events</Trans>
        </div>
      ) : (
        <>
          <div className="header-wrapper">
            <div className="button-icon">
              <ArrowLeft onClick={closeEventDetail()} />
            </div>
            {mobileView ? (
              <div className="mobile-header-event-detail">
                <div className="header-day">
                  <span className="header-text">{from && getDay(from)}</span>
                  <span className="header-text day">{from && getDayAndMonthDate(from)}</span>
                </div>
                <div className="header-time">
                  <span className="header-text time">
                    <span>{from && getTime(from)}</span>
                    <span>&nbsp;&#8208;&nbsp;{from && length && getEndTime(from, length)}</span>
                  </span>
                  <span className="header-text">
                    {timeSlot && getEnumPart(TIME_SLOT, timeSlot, 'label')}
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className="header-day">
                  <span className="header-text">{from && getDay(from)}</span>
                  <span className="header-text day">{from && getDayAndMonthDate(from)}</span>
                </div>
                <hr className="header-divider" />
                <Clock className="time" />
                <span className="header-text time">
                  <span>{from && getTime(from)}</span>
                  <span>&nbsp;&#8208;&nbsp;{from && length && getEndTime(from, length)}</span>
                </span>
                <span className="header-text">
                  {timeSlot && getEnumPart(TIME_SLOT, timeSlot, 'label')}
                </span>
              </>
            )}
          </div>
          <div className="event-detail-cell-wrapper">
            <div className="event-header">
              <div className="name-org">
                <div className="name">{name}</div>
                <div className="organization">{organization && organization.name}</div>
              </div>
              <div className="additional-info">
                <div className="button-group">
                  {platform && (
                    <div className="button">
                      <RoundedButton
                        icon={getEnumPart(PLATFORM, platform, 'icon')}
                        text={getEnumPart(PLATFORM, platform, 'label')}
                        staticBtn
                      />
                    </div>
                  )}
                  {privacy && (
                    <div className="button">
                      <RoundedButton
                        icon={getEnumPart(PRIVACY, privacy, 'icon')}
                        text={getEnumPart(PRIVACY, privacy, 'label')}
                        staticBtn
                      />
                    </div>
                  )}
                </div>
                <div className="zone">{zone && getEnumPart(ZONES, zone, 'label')}</div>
              </div>
            </div>
            <div className="detail">
              <div
                className="description"
                dangerouslySetInnerHTML={{__html: description && description}}
              />

              <div className="image event-detail">
                {pictureUrl ? (
                  <img className="picture" alt="logo" src={pictureUrl} />
                ) : organization && organization.logoUrl ? (
                  <img alt="logo" src={organization.logoUrl} />
                ) : (
                  <div className="empty-image">
                    <div className="logo-text">Logo/foto</div>
                  </div>
                )}
              </div>
            </div>

            {!hideRegistration && <EventForm id={id && id} date={from && getDate(from)} />}

            <EventDetailShare id={id && id} date={from && getDate(from)} name={name} from={from} />

            <div className="header-wrapper">
              <div className="button-icon">
                <ArrowLeft onClick={closeEventDetail()} />
              </div>
              <hr className="header-divider" />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

EventDetail.propTypes = {
  closeEventDetail: PropTypes.func.isRequired,
  data: PropTypes.object,
  mobileView: PropTypes.bool,
}

export default EventDetail
