import React, {useCallback, useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import {ERROR_MSG} from '_helper/constants'
import EventDetail from '_component/eventDetail/EventDetail'
import DayDetailTimeSlot from './DayDetailTimeSlot'
import {fireErrorToast} from '_helper/functions'
import {Trans} from '@lingui/macro'
import {getDate, setUrlToDate} from '_helper/calendarFunctions'
import moment from 'moment'
import {useHistory} from 'react-router-dom'
import {EventsContext} from '_helper/context'

const DayDetailCell = (props) => {
  const {data, isEventDetailVisible, eventId, mobileView, closeDayDetail} = props

  let history = useHistory()

  const [dayDetailData, setDayDetailData] = useState([])
  const [eventDetailData, setEventDetailData] = useState({})
  const [eventDetailVisibility, setEventDetailVisibility] = useState(isEventDetailVisible)
  const [eventDetailId, setEventDetailId] = useState(eventId)
  const [isLoading, setLoading] = useState(true)
  let {dispatch} = useContext(EventsContext)

  const orderDayDetailData = (data) => {
    // sort the events by from
    data.map((slot) => {
      return slot.events.sort((a, b) => moment(a.from) - moment(b.from))
    })

    setDayDetailData(data)
  }

  const fetchDayDetail = useCallback(() => {
    setLoading(true)
    const dateFormatted = getDate(data.date)
    httpClient
      .get(`${endpoints.events}/${dateFormatted}`)
      .then((res) => {
        orderDayDetailData(res.data)
        setLoading(false)
      })
      .catch((err) => {
        fireErrorToast(ERROR_MSG)
        setLoading(false)
        console.error(err)
      })
  }, [data])

  const fetchEventDetail = useCallback(() => {
    setLoading(true)
    const dateFormatted = getDate(data.date)
    httpClient
      .get(`${endpoints.event}/${eventDetailId}/date/${dateFormatted}`)
      .then((res) => {
        setEventDetailData(res.data)
        setLoading(false)
        history.push(`/events/${eventDetailId}/date/${dateFormatted}`)
      })
      .catch((err) => {
        fireErrorToast(ERROR_MSG)
        setLoading(false)
        console.error(err)
      })
  }, [data, eventDetailId])

  useEffect(() => {
    if (isEventDetailVisible) {
      fetchEventDetail()
    } else {
      fetchDayDetail()
    }
  }, [fetchEventDetail, fetchDayDetail, data, isEventDetailVisible])

  useEffect(() => {
    if (!isEventDetailVisible) {
      setEventDetailVisibility(false)
    }
  }, [data])

  const openEventDetail = (detailEvent) => (event) => {
    setEventDetailId(detailEvent.id)
    setEventDetailData(detailEvent)
    setEventDetailVisibility(true)
  }

  const closeEventDetail = () => (event) => {
    setUrlToDate(history, data.date)
    fetchDayDetail()
    setEventDetailVisibility(false)
    dispatch({type: 'TOGGLE_RESIZE', data: true})
  }

  const checkIfEmptyDay = (data) => {
    let isEmpty = true
    data.forEach((slot) => {
      if (slot.events.length !== 0) {
        isEmpty = false
      }
    })
    return isEmpty
  }

  useEffect(() => {
    const element = document.querySelector('.row-selected')
    element && element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'})
    if (eventDetailVisibility) {
      let date = getDate(data.date)
      let id = eventId || eventDetailData.id
      history.push(`/events/${id}/date/${date}`)
    }
  }, [eventDetailVisibility])

  return (
    <td className="detail-cell day" id="detail-cell" colSpan={7}>
      {isLoading ? (
        <div>
          <div className="loader" />
        </div>
      ) : eventDetailVisibility ? (
        <EventDetail
          closeEventDetail={closeEventDetail}
          data={eventDetailData}
          mobileView={mobileView}
        />
      ) : !checkIfEmptyDay(dayDetailData) ? (
        <DayDetailTimeSlot
          data={dayDetailData}
          openEventDetail={openEventDetail}
          mobileView={mobileView}
          closeDayDetail={closeDayDetail && closeDayDetail}
        />
      ) : (
        <div className="empty-cell day-detail">
          {dayDetailData[0] && dayDetailData[0].businessDayOff ? (
            <span className="holiday">{dayDetailData[0].businessDayOff.name}</span>
          ) : (
            <Trans>No events</Trans>
          )}
        </div>
      )}
    </td>
  )
}

DayDetailCell.propTypes = {
  data: PropTypes.object,
  isEventDetailVisible: PropTypes.bool,
  mobileView: PropTypes.bool,
  eventId: PropTypes.number,
  closeDayDetail: PropTypes.func,
}

export default DayDetailCell
