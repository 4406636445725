import React from 'react'
import EluviaLogoWhite from '_asset/img/eluvia_logo_white.png'
import EluviaLogoDark from '_asset/img/eluvia_logo_dark.png'
import {Trans} from '@lingui/macro'
import {ReactComponent as Footer} from '_asset/svg/footer.svg'

const PageFooter = (props) => {
  const {lightScheme} = props

  return (
    <div className="footer">
      <a href="https://www.eluvia.com" target="_blank">
        <img
          alt="eventito-logo"
          src={lightScheme ? EluviaLogoDark : EluviaLogoWhite}
          className="footer-logo"
        />
      </a>
      <a href="https://www.eluvia.com" target="_blank">
        <div className="footer-launch">
          <span>
            <Trans>Launched from land to cloud by Eluvians</Trans>
          </span>
          <span className="footer-icons">
            <Footer />
          </span>
        </div>
      </a>
    </div>
  )
}

export default PageFooter
