import catalogEn from 'App/_locale/en/messages'
import catalogCs from 'App/_locale/cs/messages'

export const LANGUAGES = [
  {value: 'cs', label: 'Czech'},
  {value: 'en', label: 'English'},
]
export const LANGUAGE_CATALOGS = {
  cs: catalogCs,
  en: catalogEn,
}
