import React from 'react'
import {ReactComponent as Close} from '_asset/svg/close.svg'
import PropTypes from 'prop-types'
import {getDay, getDayAndMonthDate, getTime} from '_helper/calendarFunctions'
import {Trans} from '@lingui/macro'

const QrModal = (props) => {
  const {closeModal, qrCode, name, from} = props
  const day = getDay(from)
  const date = getDayAndMonthDate(from)
  const time = getTime(from)

  const downloadQrCode = () => {
    let a = document.createElement('a') //create a link
    a.href = `data:image/png;base64,${qrCode}` //provide data
    a.download = `${name}-QR.png` // set name
    a.click() //downloaded file
  }

  return (
    <div onClick={closeModal} className="overlay">
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <div className="close-button" onClick={closeModal}>
          <Close />
        </div>
        <img src={`data:image/png;base64,${qrCode}`} alt="QR code" />
        <div className="name">{name}</div>
        <div className="from">
          <span className="day">{day}</span>
          <span>{date} -</span>
          <span>{time}</span>
        </div>
        <div className="save-button">
          <button onClick={downloadQrCode}>
            <Trans>Save</Trans>
          </button>
        </div>
      </div>
    </div>
  )
}
QrModal.propTypes = {
  closeModal: PropTypes.func,
  qrCode: PropTypes.string,
  name: PropTypes.string,
  from: PropTypes.string,
}

export default QrModal
