import React from 'react'
import PropTypes from 'prop-types'
import {ARE_EMPTY_SLOTS_IN_WEEK_CALENDAR, ZONES} from '_helper/constants'
import {getEndTime, getEnumPart, getTime} from '_helper/calendarFunctions'
import {ReactComponent as Microphone} from '_asset/svg/microphone.svg'

const WeekDetailCell = (props) => {
  const {data, onDayClick} = props

  const renderOnlineIcon = (platform) => {
    if (platform === 'ONLINE') {
      return (
        <div className="online">
          <Microphone />
        </div>
      )
    }
  }

  const renderTimeSlotsCells = () => {
    if (Object.keys(data).length !== 0 && data.constructor === Object) {
      const {id, name, from, zone, platform, length} = data
      return (
        <div key={'detail'} className="event" onClick={onDayClick(id)}>
          <span className="event-text">{name}</span>
          <div className="event-time-zone">
            <span>{from && getTime(from)}</span>
            <span>&nbsp;&#8208;&nbsp;{from && length && getEndTime(from, length)}</span>
          </div>

          <img alt="zone" src={zone && getEnumPart(ZONES, zone, 'icon')} />
          {platform && renderOnlineIcon(platform)}
        </div>
      )
    } else {
      return <div key={'detail'} />
    }
  }

  return (
    <>
      {!data.reason ? (
        ARE_EMPTY_SLOTS_IN_WEEK_CALENDAR ? (
          data && data.length !== 0 ? (
            <td>
              {data.map((event, index) => {
                const {name, from, zone, platform, id} = event
                return Object.keys(event).length !== 0 && event.constructor === Object ? (
                  <div key={index} className="event" onClick={onDayClick(id)}>
                    <span className="event-text">{name}</span>
                    <div>
                      <span className="event-time">{from && getTime(from)}</span>
                      <span className="event-zone">
                        {zone && getEnumPart(ZONES, zone, 'label')}
                      </span>
                    </div>

                    <img alt="zone" src={zone && getEnumPart(ZONES, zone, 'icon')} />
                    {platform && renderOnlineIcon(platform)}
                  </div>
                ) : (
                  <div key={index} />
                )
              })}
            </td>
          ) : (
            <td>
              {/* empty cell to complete design */}
              <div className="event" />
            </td>
          )
        ) : (
          <td>{renderTimeSlotsCells()}</td>
        )
      ) : (
        <td rowSpan={1} className={'holiday-cell'}>
          <div className="event holiday">
            <span className="event-text">{data.name && data.name}</span>
          </div>
        </td>
      )}
    </>
  )
}

WeekDetailCell.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onDayClick: PropTypes.func.isRequired,
}

export default WeekDetailCell
