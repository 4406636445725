import React, {useEffect, useState} from 'react'
import {ReactComponent as UserCircle} from '_asset/svg/user_circle.svg'
import {ReactComponent as Email} from '_asset/svg/email.svg'
import {ReactComponent as ArrowDown} from '_asset/svg/arrow_down.svg'
import httpClient from '_helper/httpClient'
import endpoints from '_config/endpoints'
import PropTypes from 'prop-types'
import {fireErrorToast, fireSuccessToast} from '_helper/functions'
import {ERROR_MSG} from '_helper/constants'
import {Trans} from '@lingui/macro'
import cx from 'classnames'

const EventForm = (props) => {
  const {id, date} = props
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [errorFields, setErrorFields] = useState([])
  const [signedIn, setSignedIn] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const handleSetName = (event) => {
    setErrorFields((prevState) => prevState.filter((field) => field !== 'name'))
    setName(event.target.value)
  }
  const handleSetEmail = (event) => {
    setErrorFields((prevState) => prevState.filter((field) => field !== 'email'))
    setEmail(event.target.value)
  }

  const prepareSubscribeParams = {
    name: name,
    email: email,
  }

  const isValid = () => {
    setErrorFields([])
    let isValid = true
    if (name.trim().length < 3) {
      setErrorFields((prevState) => [...prevState, 'name'])
      isValid = false
    }
    if (
      !email
        .trim()
        .match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setErrorFields((prevState) => [...prevState, 'email'])
      isValid = false
    }
    return isValid
  }

  const createSignedInCookie = (id) => {
    let cookieExpiration = new Date(date)
    // add one day to date of event happening
    cookieExpiration.setDate(cookieExpiration.getDate() + 1)
    // format date to UTC - cookie date format
    const cookieExpirationUTC = cookieExpiration.toUTCString()
    // set cookie
    document.cookie = `event-${id}=true; expires=${cookieExpirationUTC}`
  }

  const handleAlreadySubscribeError = (err) => {
    if (err?.data?.message === 'User already subscribed.') {
      return fireErrorToast(<Trans>User already subscribed</Trans>)
    }
    return fireErrorToast(ERROR_MSG)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (isValid() && !isLoading) {
      setLoading(true)
      httpClient
        .put(`${endpoints.event}/${id}/date/${date}/subscribe`, prepareSubscribeParams)
        .then((res) => {
          setLoading(false)
          createSignedInCookie(id)
          setSignedIn(true)
          fireSuccessToast(<Trans>You have been signed in</Trans>)
        })
        .catch((err) => {
          setLoading(false)
          handleAlreadySubscribeError(err.response)
          console.error(err.response)
        })
    }
  }

  const isAlreadySignedIn = () => {
    const cookies = document.cookie.split('; ')
    const cookieValue = cookies.find((row) => row.startsWith(`event-${id}`))

    return !!cookieValue
  }

  useEffect(() => {
    setSignedIn(isAlreadySignedIn)
  }, [])

  const getInputClasses = (field) => {
    return cx({
      'error-input': errorFields.includes(field),
      'input-with-icon': true,
    })
  }

  // TODO: find a way to translate placeholders
  return (
    <>
      <div className="divider">
        <div className="button-icon">
          <ArrowDown />
        </div>
      </div>
      <div className="divider-text">
        {signedIn ? (
          <div className="already-signed">
            <Trans>You already signed for this event</Trans>
          </div>
        ) : (
          <Trans>Interested in the event</Trans>
        )}
      </div>
      {!signedIn && (
        <div className="event-form">
          <form onSubmit={handleSubmit}>
            <div className={getInputClasses('name')}>
              <div className="input-icon">
                <UserCircle />
              </div>
              <input
                type="text"
                name="name"
                placeholder="Jméno a příjmení"
                value={name}
                onChange={handleSetName}
                required
              />
              <div className="error-msg">
                {errorFields.includes('name') && <Trans>Invalid name</Trans>}
              </div>
            </div>

            <div className={getInputClasses('email')}>
              <div className="input-icon">
                <Email />
              </div>
              <input
                type="email"
                name="email"
                placeholder="E-mail"
                value={email}
                onChange={handleSetEmail}
                required
              />
              <div className="error-msg">
                {errorFields.includes('email') && <Trans>Invalid email</Trans>}
              </div>
            </div>
            <button type="submit" className={isLoading ? 'loading' : ''}>
              {isLoading ? (
                <>
                  <div className="loader-wrapper">
                    <div className="loader" />
                  </div>
                  <span className="button-text">
                    <Trans>Sign In</Trans>
                  </span>
                </>
              ) : (
                <Trans>Sign In</Trans>
              )}
            </button>
          </form>
        </div>
      )}
    </>
  )
}

EventForm.propTypes = {
  id: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
}

export default EventForm
