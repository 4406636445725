import React, {useContext, useEffect} from 'react'
import DayDetailCell from './DayDetailCell'
import PropTypes from 'prop-types'
import {EventsContext} from '_helper/context'

const DayDetail = (props) => {
  const {data, eventDetail, mobileView, closeDayDetail} = props
  let {dispatch} = useContext(EventsContext)

  useEffect(() => {
    dispatch({type: 'TOGGLE_RESIZE', data: true})
  }, [data, eventDetail.id])

  return (
    <tr id="detail-row" key="detail">
      <DayDetailCell
        mobileView={mobileView}
        isEventDetailVisible={eventDetail.visible}
        eventId={eventDetail.id && Number(eventDetail.id)}
        data={data}
        closeDayDetail={closeDayDetail}
      />
    </tr>
  )
}

DayDetail.propTypes = {
  eventDetail: PropTypes.object,
  date: PropTypes.object,
  mobileView: PropTypes.bool,
}

export default DayDetail
