import axios from 'axios'
import {v4} from 'uuid'
import {PUBLIC_API_KEY} from '_helper/constants'

axios.defaults.headers.common.uuid = v4()
axios.defaults.headers.common['X-PUBLIC-API-KEY'] = PUBLIC_API_KEY

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (!error.response) {
      throw new Error('Server connection issue')
    }

    return Promise.reject(error)
  }
)

const post = (
  url,
  data = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  return axios.post(url, data, config)
}

const get = (
  url,
  params = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  const configCopy = config
  configCopy.params = params
  return axios.get(url, configCopy)
}

const put = (url, data = {}, config = {}) => {
  return axios.put(url, data, config)
}

const patch = (url, data = {}, config = {}) => {
  return axios.patch(url, data, config)
}

const del = (url, config = {}) => {
  return axios.delete(url, config)
}

const httpClient = {
  post,
  get,
  put,
  del,
  patch,
}

export default httpClient
