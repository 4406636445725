import React from 'react'
import PropTypes from 'prop-types'
import {getDay} from '_helper/calendarFunctions'

const Cell = (props) => {
  const {selected, rowSelected, cell, openDetail, mobileView} = props

  const selectedCellClass = selected ? ' selected-cell' : ''

  const renderDay = () => {
    if (mobileView) {
      if (rowSelected) {
        return <div className="cell-day">{getDay(cell.date, 'short')}</div>
      }
    } else {
      if (rowSelected) {
        return <div className="cell-day">{getDay(cell.date)}</div>
      }
    }
  }

  return (
    <td onClick={openDetail}>
      <div className={`cell-wrapper${selectedCellClass}`}>
        <div className="cell-date">{cell.date && cell.date.date()}</div>
        {renderDay()}
      </div>
    </td>
  )
}

Cell.propTypes = {
  selected: PropTypes.bool,
  rowSelected: PropTypes.bool,
  cell: PropTypes.object.isRequired,
  openDetail: PropTypes.func,
  mobileView: PropTypes.bool,
}

export default Cell
