import React from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as ArrowRight} from '_asset/svg/arrow_right.svg'

const DetailButton = (props) => {
  const {text, onClick} = props

  return (
    <button onClick={onClick} className="detail-button">
      <div className="button-content">
        <div className="button-text">{text}</div>
        <div className="button-icon">
          <ArrowRight />
        </div>
      </div>
    </button>
  )
}

DetailButton.propTypes = {
  text: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default DetailButton
