import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {createCalendarGrid, scrollToMainTable} from '_helper/functions'
import Cell from '_component/Cell'
import DayDetailCell from '_component/dayDetail/DayDetailCell'
import WeekDetail from '_component/weekDetail/WeekDetail'
import DayDetail from '_component/dayDetail/DayDetail'
import {
  addDesktopWeekDetail,
  addMobileDayDetail,
  closeAllDetails,
  fillCalendarWithDates,
  getCurrentOrFirstDayAndWeekInMonthIndex,
  getCurrentOrFirstWeekInMonthIndex,
  getDate,
  setUrlToDate,
  setUrlToEvents,
} from '_helper/calendarFunctions'
import RoundedButton from '_component/RoundedButton'
import {ReactComponent as Expand} from '_asset/svg/expand.svg'
import {ReactComponent as Collapse} from '_asset/svg/collapse.svg'
import {ReactComponent as Microphone} from '_asset/svg/microphone.svg'
import {ReactComponent as People} from '_asset/svg/people.svg'
import {DATE_FORMAT_REGEX} from '_helper/constants'
import {useHistory} from 'react-router-dom'

const Calendar = (props) => {
  const {selectedMonth, selectedYear, mobileView, id, date} = props

  let history = useHistory()

  const [grid, setGrid] = useState(createCalendarGrid())
  const [detail, setDetail] = useState({rowIndex: null, colIndex: null, type: 'week'})
  const [eventDetail, setEventDetail] = useState({visible: false, id: null})
  const [allWeeksOpened, setAllWeeksOpened] = useState(false)
  const [updateCalendar, setUpdateCalendar] = useState(false)
  const [platformFilter, setPlatformFilter] = useState(null)
  const [lastRowSelected, setLastRowSelected] = useState(null)

  const openFromAllWeeksOpened = (rowIndex) => {
    setAllWeeksOpened(false)
    let newGrid = [...grid]
    newGrid = closeAllDetails(newGrid)
    newGrid.splice(rowIndex / 2 + 1, 0, 'week-detail')
    setDetail({rowIndex: rowIndex / 2 + 1, colIndex: null, type: 'week'})
    setGrid(newGrid)
    scrollToMainTable()
  }

  const openDetail = (rowIndex, colIndex) => (event) => {
    if (allWeeksOpened) {
      return openFromAllWeeksOpened(rowIndex)
    }

    setEventDetail({visible: false, id: null})
    let newGrid = [...grid]

    if (mobileView) {
      if (newGrid[rowIndex + 1] === 'day-detail' && detail.colIndex === colIndex) {
        setDetail({rowIndex: null, colIndex: null, type: null})
        setUrlToEvents(history)
        newGrid = closeAllDetails(newGrid, true)
      } else {
        setUrlToDate(history, newGrid[rowIndex][colIndex].date)
        newGrid = closeAllDetails(newGrid, true)
        addMobileDayDetail(newGrid, detail, rowIndex, colIndex, setDetail)
      }
    } else {
      if (newGrid[rowIndex + 1] === 'week-detail') {
        newGrid[rowIndex + 1] = 'day-detail'
        setUrlToDate(history, newGrid[rowIndex][colIndex].date)
        setDetail({rowIndex: rowIndex + 1, colIndex: colIndex, type: 'day'})
      } else if (newGrid[rowIndex + 1] === 'day-detail') {
        setUrlToDate(history, newGrid[rowIndex][colIndex].date)
        let type = 'day'
        if (detail.colIndex === colIndex) {
          setUrlToEvents(history)
          newGrid[rowIndex + 1] = 'week-detail'
          type = 'week'
        }

        setDetail({rowIndex: rowIndex + 1, colIndex: colIndex, type: type})
      } else {
        setUrlToEvents(history)
        // if mobile view, then add column instead of row (table is rendered in column view)

        // if click on cell in row which is not week or day detail then close other details
        newGrid = closeAllDetails(newGrid)
        addDesktopWeekDetail(newGrid, detail, rowIndex, colIndex, setDetail, setLastRowSelected)
      }
    }

    // set grid to state
    setGrid(newGrid)
  }

  const isSelectedRow = (rowIndex) => {
    if (!mobileView) {
      // selected cell is always above the detail row therefore (detail.rowIndex - 1)
      if (rowIndex === detail.rowIndex - 1) {
        return true
      }
    } else {
      if (rowIndex === detail.rowIndex) {
        return true
      }
    }

    return false
  }

  const selectedRowClassName = (rowIndex) => {
    if (isSelectedRow(rowIndex)) {
      return 'row-selected'
    }
  }

  const isSelectedCell = (rowIndex, colIndex) => {
    if (mobileView) {
      if (rowIndex === detail.rowIndex && colIndex === detail.colIndex && detail.type === 'day') {
        return true
      }
    } else {
      // selected cell is always above the detail row therefore (detail.rowIndex - 1)
      if (
        rowIndex === detail.rowIndex - 1 &&
        colIndex === detail.colIndex &&
        detail.type === 'day'
      ) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    let newGrid = closeAllDetails([...grid])
    setGrid(newGrid)
  }, [mobileView])

  const toggleAllWeeksDetail = () => {
    let newGrid = closeAllDetails([...grid])

    if (allWeeksOpened) {
      setAllWeeksOpened(false)
      setDetail({rowIndex: lastRowSelected, colIndex: null, type: 'week'})
      newGrid = openCurrentOrFirstWeekDetail(newGrid, true)
    } else {
      setAllWeeksOpened(true)
      setDetail({rowIndex: null, colIndex: null, type: 'week'})
      newGrid.forEach((row, rowIndex) => {
        //add week detail under every non detail row
        newGrid.splice(rowIndex * 2 + 1, 0, 'week-detail')
      })
    }

    setGrid(newGrid)
  }

  const openCurrentOrFirstWeekDetail = (newGrid, openLastRowSelected = false) => {
    if (!mobileView) {
      if (openLastRowSelected) {
        newGrid.splice(lastRowSelected + 1, 0, 'week-detail')
        setDetail({rowIndex: lastRowSelected + 1, colIndex: null, type: 'week'})
      } else {
        const weekDetailIndex = getCurrentOrFirstWeekInMonthIndex(
          newGrid,
          selectedYear,
          selectedMonth
        )
        setLastRowSelected(weekDetailIndex - 1)
        newGrid.splice(weekDetailIndex, 0, 'week-detail')
        setDetail({rowIndex: weekDetailIndex, colIndex: null, type: 'week'})
      }
    } else {
      const {weekIndex, dayIndex} = getCurrentOrFirstDayAndWeekInMonthIndex(
        selectedYear,
        selectedMonth
      )
      newGrid.splice(weekIndex + 1, 0, 'day-detail')
      setDetail({rowIndex: weekIndex, colIndex: dayIndex - 1, type: 'day'})
    }
    return newGrid
  }

  useEffect(() => {
    fillCalendarWithDates(selectedYear, selectedMonth, grid, setGrid)

    // collapse all weeks
    setAllWeeksOpened(false)
    // reset last selected row
    setLastRowSelected(null)
    // update calendar on every refill with new dates
    setUpdateCalendar((prevState) => !prevState)

    let newGrid = closeAllDetails([...grid], mobileView)

    // open event or day from url
    if (date && date.match(DATE_FORMAT_REGEX)) {
      if (mobileView) {
        grid.forEach((row, rowIndex) => {
          Array.isArray(row) &&
            row.forEach((col, colIndex) => {
              if (getDate(col.date) === date) {
                // change week view to day view
                newGrid.splice(rowIndex + 1, 0, 'day-detail')
                if (id) {
                  setEventDetail({visible: true, id: id})
                }
                setDetail({rowIndex: rowIndex, colIndex: colIndex, type: 'day'})
                setUpdateCalendar((prevState) => !prevState)
              }
            })
        })
      } else {
        grid.forEach((row, rowIndex) => {
          Array.isArray(row) &&
            row.forEach((col, colIndex) => {
              if (getDate(col.date) === date) {
                // change week view to day view
                newGrid.splice(rowIndex + 1, 0, 'day-detail')
                if (id) {
                  setEventDetail({visible: true, id: id})
                }
                setDetail({rowIndex: rowIndex + 1, colIndex: colIndex, type: 'day'})
                setUpdateCalendar((prevState) => !prevState)
                setLastRowSelected(rowIndex)
              }
            })
        })
      }
    } else {
      newGrid = openCurrentOrFirstWeekDetail(newGrid)
    }
    setGrid(newGrid)
  }, [selectedMonth, mobileView])

  const onDayClick = (row) => (col) => (id) => (event) => {
    let newGrid = [...grid]

    // change week view to day view
    newGrid.splice(row, 1, 'day-detail')
    // set detail day view
    setEventDetail({visible: true, id: id})

    if (allWeeksOpened) {
      newGrid = newGrid.filter((row) => row !== 'week-detail')
      const newDetailRow = Math.ceil(row / 2)
      setDetail({rowIndex: newDetailRow, colIndex: col, type: 'day'})
      setAllWeeksOpened(false)
    } else {
      setDetail({rowIndex: row, colIndex: col, type: 'day'})
    }

    setGrid(newGrid)
  }

  const handleCalendarFilter = (filter) => (event) => {
    if (platformFilter === filter) {
      return setPlatformFilter(null)
    }
    setPlatformFilter(filter)
  }

  const closeDayDetail = () => {
    // easiest way to go back from day detail to week detail
    // simulate the click on day == unselect day and go to week
    document.querySelector('.selected-cell').click()
  }

  return (
    <div className="table-wrapper">
      <table className="calendar-table" id="main-table">
        <thead>
          <tr>
            <th colSpan={7}>
              <div className="control-buttons">
                <RoundedButton
                  selected={allWeeksOpened}
                  onClick={toggleAllWeeksDetail}
                  text={<Trans>Expand entire months</Trans>}
                  icon={allWeeksOpened ? <Collapse /> : <Expand />}
                />
                <div className="filters">
                  <RoundedButton
                    selected={platformFilter === 'ONLINE'}
                    onClick={handleCalendarFilter('ONLINE')}
                    text={<Trans>Online events</Trans>}
                    icon={<Microphone />}
                  />
                  <RoundedButton
                    selected={platformFilter === 'OFFLINE'}
                    onClick={handleCalendarFilter('OFFLINE')}
                    text={<Trans>Offline events</Trans>}
                    icon={<People />}
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {grid.map((row, rowIndex) =>
            row === 'week-detail' ? (
              <WeekDetail
                key={rowIndex}
                dates={grid[rowIndex - 1]}
                onDayClick={onDayClick(rowIndex)}
                updateCalendar={updateCalendar}
                platformFilter={platformFilter}
              />
            ) : row === 'day-detail' ? (
              <DayDetail
                mobileView={mobileView}
                key={rowIndex}
                data={grid[rowIndex - 1][detail.colIndex]}
                eventDetail={eventDetail}
                closeDayDetail={closeDayDetail}
              />
            ) : (
              <tr key={rowIndex} className={selectedRowClassName(rowIndex)}>
                {row.map((cell, colIndex) =>
                  cell === 'day-detail' ? (
                    <DayDetailCell // phone view of detail cell
                      isEventDetailVisible={eventDetail.visible}
                      eventId={Number(eventDetail.id)}
                      mobileView={mobileView}
                      key={`${rowIndex}-${colIndex}`}
                      type="cell"
                      data={grid[detail.rowIndex][detail.colIndex]}
                    />
                  ) : (
                    <Cell
                      openDetail={openDetail(rowIndex, colIndex)}
                      selected={isSelectedCell(rowIndex, colIndex)}
                      rowSelected={isSelectedRow(rowIndex)}
                      cell={cell}
                      key={`${rowIndex}-${colIndex}`}
                      mobileView={mobileView}
                    />
                  )
                )}
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  )
}

Calendar.propTypes = {
  selectedMonth: PropTypes.number.isRequired,
  selectedYear: PropTypes.number.isRequired,
  mobileView: PropTypes.bool,
}

export default Calendar
