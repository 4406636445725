import moment from 'moment'
import {DATE_FORMAT, DAY_MONTH_FORMAT, DAYS, TIME_FORMAT} from '_helper/constants'

export const addMobileDayDetail = (newGrid, detail, rowIndex, colIndex, setDetail) => {
  // add one, so we count with opened detail row as well
  let newDetailIndex = rowIndex + 1

  // if previous detail row is above new row index
  // then new detail index will not need to add 1 to indexes
  // we do not need to count opened detail
  if (detail.rowIndex < rowIndex) {
    newDetailIndex = rowIndex
  }
  // if detail is closed we have to add (open) extra row for detail
  if (detail.rowIndex === null) {
    newDetailIndex += 1
  }

  setDetail({rowIndex: newDetailIndex - 1, colIndex: colIndex, type: 'day'})

  // insert detail row
  newGrid.splice(newDetailIndex, 0, 'day-detail')
}

export const addDesktopWeekDetail = (
  newGrid,
  detail,
  rowIndex,
  colIndex,
  setDetail,
  setLastRowSelected
) => {
  let newDetailIndex = rowIndex

  // if previous detail row was null greater than row index
  // we add new detail under clicked row
  if (detail.rowIndex > rowIndex || detail.rowIndex === null) {
    newDetailIndex = rowIndex + 1
  }

  setDetail({rowIndex: newDetailIndex, colIndex: colIndex, type: 'week'})
  // insert week detail
  setLastRowSelected(newDetailIndex - 1)
  newGrid.splice(newDetailIndex, 0, 'week-detail')
}

export const fillCalendarWithDates = (selectedYear, selectedMonth, grid, setGrid) => {
  // get number of week in year (i.e. 40)
  const firstWeekOfMonth = getFirstWeekOfMonth(selectedYear, selectedMonth)

  // get first day of first week in month (because it might be a day of previous month)
  // and add one day to it, to adjust calendar to start with Monday not Sunday
  let myDate = moment().weekYear(selectedYear).week(firstWeekOfMonth).startOf('week').add(1, 'd')

  const newGrid = [...grid]

  newGrid.forEach((row, rowIndex) => {
    if (grid[rowIndex] === 'day-detail' || grid[rowIndex] === 'week-detail') return

    row.forEach((col, colIndex) => {
      if (grid[rowIndex][colIndex] === 'day-detail') return

      // moment must use clone, otherwise same date will be placed in all cells of grid
      const dateClone = myDate.clone()
      grid[rowIndex][colIndex] = {date: dateClone}
      myDate.add(1, 'd')
    })
  })

  return setGrid(newGrid)
}

export const getCurrentOrFirstWeekInMonthIndex = (newGrid, selectedYear, selectedMonth) => {
  const firstWeekOfMonth = getFirstWeekOfMonth(selectedYear, selectedMonth)

  let currentWeek = getCurrentWeek(selectedYear, selectedMonth)
  // add one because we open detail under current week row
  let weekDetailIndex = currentWeek - firstWeekOfMonth + 1
  if (
    weekDetailIndex <= 0 ||
    weekDetailIndex > newGrid.length + 1 ||
    selectedYear !== moment().year()
  ) {
    weekDetailIndex = 1
  }
  return weekDetailIndex
}

export const getCurrentWeek = (selectedYear, selectedMonth) => {
  let currentWeek = moment().weekYear(selectedYear).week()
  let currentDay = moment().day()

  // if december and week is number 1 then display last week in year
  if (currentWeek === 1 && selectedMonth === 11) {
    currentWeek = 52
  } else if (currentDay === 0) {
    // if currentDay is index 0 === Sunday (english calendar starts on Sunday)
    // deduct one from current week, so we get right week for our calendar
    currentWeek -= 1
  }

  return currentWeek
}

export const getCurrentOrFirstDayAndWeekInMonthIndex = (selectedYear, selectedMonth) => {
  let indexes = {}
  let currentWeek = getCurrentWeek(selectedYear)

  if (currentWeek === 1 && selectedMonth === 11) {
    currentWeek = 52
  }

  // check if you are on current month of current year or not
  if (moment().month() !== selectedMonth || moment().year() !== selectedYear) {
    indexes = {weekIndex: 0, dayIndex: 1}
  } else {
    const firstWeekIndex = getFirstWeekOfMonth(selectedYear, selectedMonth)

    let weekIndex = currentWeek - firstWeekIndex
    // moment().format('E') is getting day indexes from 1 to 7
    let dayIndex = moment().format('E')

    indexes = {weekIndex: weekIndex, dayIndex: dayIndex}
  }

  return indexes
}

export const getFirstWeekOfMonth = (selectedYear, selectedMonth) => {
  return moment().year(selectedYear).month(selectedMonth).startOf('month').week()
}

// if the date is not a moment then create moment from the date
export const ensureMoment = (date) => {
  let momentDate = date
  if (!moment.isMoment(moment)) {
    momentDate = moment(date)
  }
  return momentDate
}

// index move due to the english week calendar starting with sunday
export const getDay = (date, type = 'long') => {
  const momentDate = ensureMoment(date)
  if (type === 'short') {
    return momentDate.day() === 0 ? DAYS[6].short : DAYS[momentDate.day() - 1].short
  }
  return momentDate.day() === 0 ? DAYS[6].long : DAYS[momentDate.day() - 1].long
}

export const getDayAndMonthDate = (date) => {
  const momentDate = ensureMoment(date)
  return momentDate.format(DAY_MONTH_FORMAT)
}

export const getEnumPart = (ENUM, value, part) => {
  return ENUM.filter((enu) => enu.value === value)[0][part]
}

export const getDate = (date) => {
  const momentDate = ensureMoment(date)
  return momentDate.format(DATE_FORMAT)
}

export const getTime = (date) => {
  const momentDate = ensureMoment(date)
  return momentDate.format(TIME_FORMAT)
}

export const getEndTime = (from, length) => {
  return moment(from)
    .add(Math.round(length * 60), 'minutes')
    .format(TIME_FORMAT)
}

// close all opened details
export const closeAllDetails = (oldGrid) => {
  let newGrid = []
  newGrid = oldGrid.filter((row) => row !== 'week-detail')
  newGrid = newGrid.filter((row) => row !== 'day-detail')
  newGrid.forEach((row, index) => {
    const filtered = row.filter((col) => col !== 'day-detail')
    newGrid.splice(index, 1, filtered)
  })

  return newGrid
}

export const setUrlToEvents = (history) => {
  history.push('/events')
}
export const setUrlToDate = (history, date) => {
  history.push(`/events/${getDate(date)}`)
}
