import React, {useState} from 'react'
import {Trans} from '@lingui/macro'
import {ReactComponent as Facebook} from '_asset/svg/facebook.svg'
import {ReactComponent as Language} from '_asset/svg/language.svg'
import {ReactComponent as QRCode} from '_asset/svg/qr_code.svg'
import {ReactComponent as Share} from '_asset/svg/share.svg'
import copy from 'copy-to-clipboard'
import httpClient from '_helper/httpClient'
import PropTypes from 'prop-types'
import endpoints from '_config/endpoints'
import {fireErrorToast, fireSuccessToast} from '_helper/functions'
import {ERROR_MSG} from '_helper/constants'
import QrModal from '_component/eventDetail/QrModal'
import cx from 'classnames'

const EventDetailShare = (props) => {
  const {id, date, name, from} = props

  const [isLoading, setLoading] = useState(false)
  const [shareData, setShareData] = useState({})
  const [qrVisible, setQRVisible] = useState(false)

  const fetchEventSharing = () => {
    setLoading(true)
    return httpClient
      .get(`${endpoints.event}/${id}/date/${date}/sharing`)
      .then((res) => {
        setLoading(false)
        setShareData(res.data)
        return res.data
      })
      .catch((err) => {
        setLoading(false)
        fireErrorToast(ERROR_MSG)
        console.error(err)
      })
  }

  const shareFacebook = () => {
    if (Object.keys(shareData).length !== 0 && shareData.constructor === Object) {
      const encodedUrl = encodeURI(shareData.shortUrl)
      const FBlink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`
      window.open(FBlink, '_blank')
    } else {
      fetchEventSharing()
        .then((res) => {
          const encodedUrl = encodeURI(res.shortUrl)
          const FBlink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`
          window.open(FBlink, '_blank')
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  const copyURL = () => {
    if (Object.keys(shareData).length !== 0 && shareData.constructor === Object) {
      copy(shareData.shortUrl, {format: 'text/html'})
      fireSuccessToast(<Trans>Url: {shareData.shortUrl} copied.</Trans>)
    } else {
      fetchEventSharing()
        .then((res) => {
          copy(res.shortUrl, {format: 'text/html'})
          fireSuccessToast(<Trans>Url: {res.shortUrl} copied.</Trans>)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }
  const generateQR = () => {
    if (Object.keys(shareData).length !== 0 && shareData.constructor === Object) {
      setQRVisible(true)
    } else {
      fetchEventSharing()
        .then((res) => {
          res && setQRVisible(true)
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  const closeModal = () => {
    setQRVisible(false)
  }

  const buttonClasses = cx({
    loading: isLoading,
    'share-button': true,
  })

  return (
    <>
      <div className="divider">
        <div className="button-icon">
          <Share />
        </div>
      </div>
      <div className="divider-text">
        <Trans>Share</Trans>
      </div>
      <div className="share-wrapper">
        <div className={buttonClasses} onClick={shareFacebook}>
          <Facebook />
          <div className="share-text">
            <Trans>Share on Facebook</Trans>
          </div>
          {isLoading && <div className="loader" />}
        </div>
        <div className={buttonClasses} onClick={copyURL}>
          <Language />
          <div className="share-text">
            <Trans>Copy URL</Trans>
          </div>
          {isLoading && <div className="loader" />}
        </div>
        <div className={buttonClasses} onClick={generateQR}>
          <QRCode />
          <div className="share-text">
            <Trans>Generate QR code</Trans>
          </div>
          {isLoading && <div className="loader" />}
        </div>
      </div>
      {qrVisible && (
        <QrModal qrCode={shareData.qrCode} closeModal={closeModal} name={name} from={from} />
      )}
    </>
  )
}

EventDetailShare.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  from: PropTypes.string,
  date: PropTypes.string,
}

export default EventDetailShare
