import {Trans} from '@lingui/macro'
import React from 'react'
import CoworkingCafe from '_asset/img/cafe.png'
import CoworkingKitchen from '_asset/img/kitchen.png'
import EasyEdu from '_asset/img/easy_edu.png'
import Gaming from '_asset/img/gaming.png'
import MagentaHome from '_asset/img/home.png'
import Podcast from '_asset/img/podcast.png'
import Tuning from '_asset/img/tuning_stage.png'
import CoworkingCafeNoText from '_asset/img/cafe-no-text.png'
import CoworkingKitchenNoText from '_asset/img/kitchen-no-text.png'
import EasyEduNoText from '_asset/img/edu-no-text.png'
import GamingNoText from '_asset/img/gaming-no-text.png'
import MagentaHomeNoText from '_asset/img/home-no-text.png'
import PodcastNoText from '_asset/img/podcast-no-text.png'
import TuningNoText from '_asset/img/tuning-stage-no-text.png'
import {ReactComponent as Microphone} from '_asset/svg/microphone.svg'
import {ReactComponent as People} from '_asset/svg/people.svg'
import {ReactComponent as Public} from '_asset/svg/public_door.svg'
import {ReactComponent as Private} from '_asset/svg/private_door.svg'

import {resolveEnvironmentParam} from '_helper/functions'

export const ENVIRONMENT = process.env.NODE_ENV
export const GATEWAY_URL = resolveEnvironmentParam('GATEWAY_URL')
export const PUBLIC_API_KEY = resolveEnvironmentParam('PUBLIC_API_KEY')
export const IFRAME_URL = resolveEnvironmentParam('IFRAME_URL')

export const TIME_SLOT = [
  {value: 'MORNING', label: <Trans>morning</Trans>},
  {value: 'AFTERNOON', label: <Trans>afternoon</Trans>},
  {value: 'EVENING', label: <Trans>evening</Trans>},
  {value: 'ALL_DAY', label: <Trans>all day</Trans>},
]

export const PRIVACY = [
  {value: 'PRIVATE', label: <Trans>Private event</Trans>, icon: <Private />},
  {value: 'PUBLIC', label: <Trans>Public event</Trans>, icon: <Public />},
  {value: 'REGISTRATION', label: <Trans>Public event with Registration</Trans>, icon: <Private />},
]

export const PLATFORM = [
  {value: 'ONLINE', label: <Trans>Online event</Trans>, icon: <Microphone />},
  {value: 'OFFLINE', label: <Trans>Offline event</Trans>, icon: <People />},
]

export const DAYS = [
  {long: <Trans>Monday</Trans>, short: <Trans>Mo</Trans>},
  {long: <Trans>Tuesday</Trans>, short: <Trans>Tu</Trans>},
  {long: <Trans>Wednesday</Trans>, short: <Trans>We</Trans>},
  {long: <Trans>Thursday</Trans>, short: <Trans>Th</Trans>},
  {long: <Trans>Friday</Trans>, short: <Trans>Fr</Trans>},
  {long: <Trans>Saturday</Trans>, short: <Trans>Sa</Trans>},
  {long: <Trans>Sunday</Trans>, short: <Trans>Su</Trans>},
]

export const MONTHS = [
  <Trans>JANUARY</Trans>,
  <Trans>FEBRUARY</Trans>,
  <Trans>MARCH</Trans>,
  <Trans>APRIL</Trans>,
  <Trans>MAY</Trans>,
  <Trans>JUNE</Trans>,
  <Trans>JULY</Trans>,
  <Trans>AUGUST</Trans>,
  <Trans>SEPTEMBER</Trans>,
  <Trans>OCTOBER</Trans>,
  <Trans>NOVEMBER</Trans>,
  <Trans>DECEMBER</Trans>,
]

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_REGEX = /^[12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/
export const DAY_MONTH_FORMAT = 'DD.MM.'
export const TIME_FORMAT = 'HH:mm'

export const ZONES = [
  {
    value: 'COWORKING_CAFE',
    label: <Trans>Co-working café</Trans>,
    icon: CoworkingCafe,
    noTextIcon: CoworkingCafeNoText,
  },
  {
    value: 'COWORKING_KITCHEN',
    label: <Trans>Co-working kitchen</Trans>,
    icon: CoworkingKitchen,
    noTextIcon: CoworkingKitchenNoText,
  },
  {value: 'PODCAST', label: <Trans>Podcast</Trans>, icon: Podcast, noTextIcon: PodcastNoText},
  {
    value: 'MAGENTA_HOME',
    label: <Trans>Magenta home</Trans>,
    icon: MagentaHome,
    noTextIcon: MagentaHomeNoText,
  },
  {value: 'GAMING', label: <Trans>Gaming</Trans>, icon: Gaming, noTextIcon: GamingNoText},
  {value: 'TUNING', label: <Trans>Custom</Trans>, icon: Tuning, noTextIcon: TuningNoText},
  {value: 'EASY_EDU', label: <Trans>EasyEdu</Trans>, icon: EasyEdu, noTextIcon: EasyEduNoText},
  {value: 'ALL', label: <Trans>All</Trans>, icon: EasyEdu, noTextIcon: EasyEduNoText},
]

export const ERROR_MSG = <Trans>Something went wrong!</Trans>

export const NUMBER_OF_TOP_EVENTS_IN_ROW = 2

// if false all events are below each other without time slot separation
export const ARE_EMPTY_SLOTS_IN_WEEK_CALENDAR = false
